import React from 'react';
import { compose } from 'redux';
import { connect} from 'react-redux'
import { change, unregisterField } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import {Field, reduxForm, formValueSelector} from "redux-form";
import { required, numericality} from 'redux-form-validators';
import PropTypes from 'prop-types'
import { PRODUCT_PARAMETER_PERIOD_OPTIONS } from '../../../constants';
import FormNavigation from '../../common/FormNavigation';
import {FlexFormCol, OuterFormColLeft, OuterFormColRight, StyledExplanation} from "../../common/styles/FormStyles";
import InputWrapper from '../../common/forms/InputWrapper';
import SelectWrapper from '../../common/forms/SelectWrapper';
import { Switcher } from '../../Switcher/Switcher';
import { MAIN_COLOR, NO_ACTIVE_BG_COLOR } from '../../../constants/css';
import { parseStrToNumber } from '../../../utils/commonUtils';
import { ReactComponent as Info } from "../../../images/icons/INfo.svg";
import _ from 'lodash';
import FallbackTariffSelector from "../../common/lib/FallbackTariffSelector"

const extendExplanation = "Außerdem müssen die gültigen AGBs sowie ein Dokument zur Widerrufsbelehrung hochgeladen werden. Beide Dokumente werden Neukunden mit ihrer Auftragsbestätigung zugestellt."

class ProductContractParameterForm extends React.Component {
    /*  
    The code in componentWillUnmount is a fix. 
    After creation of new product and wanting to click on speichern und weiter on Base Design
    there were alway sync errors for the fields listed in componentWillUnmount below. 
    This error prevented clicking on "speichern und weiter" on the tabs 
    "Base Design" and "Rechnungen & Dokumente".
  */
  componentWillUnmount() {
    this.props.dispatch(unregisterField("productDefinitionForm", "revocation_period_in_days"))
    this.props.dispatch(unregisterField("productDefinitionForm", "contract_notice_period"))
    this.props.dispatch(unregisterField("productDefinitionForm", "minimum_contract_term"))
    this.props.dispatch(unregisterField("productDefinitionForm", "contract_notice_period_unit"))
  }

  renderContractValidityFields(automaticProlongation){
    if (automaticProlongation) {
      return(
        <Field
          name="automatic_prolongation_time"
          type="number"
          labelText="Vertragslaufzeit nach automatischer Verlängerung"
          component={InputWrapper}
          validate={[required(), numericality({int: true, msg: 'Ungültige Eingabe', allowBlank: true})]}
          parse={parseStrToNumber}
          disabled={this.props.disabled}
          unit="Monate"
          className="mt-5"
          infoIcon={!this.props.isProducer ? <Info/> : null}
          infoIconTooltip="Laut dem Gesetz für faire Verbraucherverträge dürfen Vertragslaufzeiten nur maximal um einen weiteren Monat verlängert werden."
        />
      )
    }
  }

  onCheckBoxChange() {
    this.props.dispatch(change('productDefinitionForm', `is_automatic_prolongation`, !this.props.automaticProlongation));
  }

  render() {
    return (
      // handleSubmit needed for local validation of fields
      <Form onSubmit={this.props.handleSubmit(() => undefined)}>
        <StyledExplanation>
          Hier können Sie Vertragsparameter festlegen, welche für alle
          Kundenverträge gelten. {!this.props.isSpot && <>{extendExplanation}</>}
        </StyledExplanation>
        <Row>
          <OuterFormColLeft md={6} lg={6}>
            <Field
              name="revocation_period_in_days"
              type="number"
              labelText="Widerrufsfrist"
              component={InputWrapper}
              validate={required()}
              parse={parseStrToNumber}
              disabled={this.props.disabled}
              unit="Tage"
              className="mt-5"
              infoIcon={<Info/>}
              infoIconTooltip="Bei Online Vertragsabschluss steht dem Kunden ein Widerrufsrecht zu. Gesetzlich vorgeschrieben sind 14 Tage."
            />
            <Row>
              <FlexFormCol md={12} lg={12}>
                <Field
                  name="contract_notice_period"
                  type="number"
                  labelText="Kündigungsfrist"
                  component={InputWrapper}
                  validate={[required(), numericality({int: true, msg: 'Ungültige Eingabe'})]}
                  parse={parseStrToNumber}
                  disabled={this.props.disabled}
                  className="mt-5 mr-3"
                />
                <Field
                  name="contract_notice_period_unit"
                  labelText="Einheit"
                  component={SelectWrapper}
                  optionsList={PRODUCT_PARAMETER_PERIOD_OPTIONS}
                  validate={[required()]}
                  disabled={this.props.disabled}
                  className="mt-5"
                />
              </FlexFormCol>
            </Row>
            {/* {this.props.automaticProlongation === true ? this.renderContractValidityFields() : this.props.dispatch(unregisterField("productDefinitionForm", "automatic_prolongation_time"))} */}
            {this.renderContractValidityFields(this.props.automaticProlongation)}
          </OuterFormColLeft>
          <OuterFormColRight md={6} lg={6}>
            <Field
              name="minimum_contract_term"
              type="number"
              labelText="Mindestvertragslaufzeit"
              component={InputWrapper}
              validate={[required(), numericality({int: true, msg: 'Ungültige Eingabe'})]}
              parse={parseStrToNumber}
              disabled={this.props.disabled}
              unit="Monate"
              className="mt-5"
              infoIcon={<Info/>}
              infoIconTooltip="Gesetzlich ist eine Mindestlaufzeit von bis zu zwei Jahren möglich."
            />
            <Field
              name="is_automatic_prolongation"
              switchId={1}
              labelText="Automatische Vertragsverlängerung"
              component={Switcher}
              handleChange={this.props.disabled ? () => undefined : this.onCheckBoxChange.bind(this)}
              isChecked={this.props.automaticProlongation}
              disabled={this.props.disabled}
              backgroundActiveColor={this.props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
              className="mt-5"
              justifyContent="start"
              activeText="ja"
              inActiveText="nein"
              positionText="right"
              infoIcon={<Info/>}
              infoIconTooltip="Eine automatische Vertragsverlängerung greift, wenn der Kunde den Vertrag nicht unter Einhaltung der Kündigungsfrist zum Ende der Mindestvertragslaufzeit kündigt."
            />
          </OuterFormColRight>
        </Row>
        {this.props.isSpot && <FallbackTariffSelector
          status={this.props.status}
          fallbackTariff={this.props.fallbackTariff}
          fallbackTariffOptions={this.props.fallbackTariffOptions}
          disabled={this.props.disabled}
          dispatch={this.props.dispatch}
        /> }
        <FormNavigation
          onSaveHandler={this.props.onSaveProductOffer}
          onPreviousPageHandler={this.props.onPreviousPage}
          nextTabKey={this.props.nextTabKey}
          previousTabKey={this.props.previousTabKey}
        />
      </Form>
    );
  }
}
ProductContractParameterForm.propTypes = {
  onSaveProductOffer: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  previousTabKey: PropTypes.string.isRequired,
  nextTabKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isProducer: PropTypes.bool
};

ProductContractParameterForm.defaultProps = {
  isProducer: false
}

const selector = formValueSelector('productDefinitionForm');

function mapStateToProps(state, ownProps) {
  const automaticProlongation = (selector(state, 'is_automatic_prolongation') === true);
  const isSpot = (selector(state, 'consumption_price_model') === "spot");

  const fallbackTariff = (selector(state, 'fallback_tariff'));
  const fallbackTariffOptions = _.get(state, 'productOffers.definition.data.fallback_tariff_options', []);
  const status = _.get(state, 'productOffers.definition.data.status', '');

  return Object.assign({}, ownProps, {
    automaticProlongation,
    initialValues: state.productOffers.definition.formData,
    isSpot,
    fallbackTariff,
    fallbackTariffOptions,
    status
  })
}

export default compose(
    connect(mapStateToProps, {change}), 
    reduxForm({
        form: "productDefinitionForm",  
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        enableReinitialize: true,
        touchOnChange: true
    }),
)(ProductContractParameterForm)
